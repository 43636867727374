var sliding = false;

$(document).ready(function () {
    var navDropdown = $('.u_nav__dropdown');
    var toggleButton = $('.toggle-nav');

    $('.toggle-nav').on('click', function () {
        toggleNavDropdown(navDropdown, toggleButton);
    });

    $('#account-trigger').on('keyup keydown', function (e) {
        if (e.key === "Enter" && e.type == 'keyup') {
            e.preventDefault();
            $('#account-trigger').trigger("click");
        } else if (e.key === "Tab" && 
            e.shiftKey && 
            e.type == 'keydown' && 
            e.target.id == 'account-trigger' && 
            $(this).children('.nav__dropdown--visible').length != 0) {
            $('#account-trigger').trigger("click");
        }
    });

    $('.nav__dropdown-list').children().last().on('keydown', function (e) {
        if (e.key === "Tab" && !e.shiftKey) {
            $('#account-trigger').trigger("click");
        }
    });

    $('.u_nav__dropdown').children().last().on('keydown', function (e) {
        if (e.key === "Tab" && !e.shiftKey) {
            toggleNavDropdown(navDropdown, toggleButton);
        }
    });

    $('.nav__dropdown-list').children().first().on('keydown', function (e) {
        if (e.key === "Tab" && e.shiftKey) {
            $('#account-trigger').trigger("click");
        }
    });

    $('.u_nav__dropdown').children('#search').on('keydown', function (e) {
        if (e.key === "Tab" && e.shiftKey && $(this).parent('.open').length != 0) {
            toggleNavDropdown(navDropdown, toggleButton);
        }
    });
    $('.toggle-nav').on('keydown', function (e) {
        if (e.key === "Tab" &&
            e.shiftKey &&
            $(this).children().parent('.open').context.className.includes('open')) {
            toggleNavDropdown(navDropdown, toggleButton);
        }
    });
});

function toggleNavDropdown(dropdown, button) {
    if (sliding) return; // Prevents schenanigans

    sliding = true;

    var openClass = "open";
    var hideClass = "hide";

    if (dropdown.hasClass(openClass)) {
        dropdown.removeClass(openClass);
        button.removeClass(openClass);
        button.attr("aria-label", window.OverDrive.SiteCopy.get("ariaLabels.openNavigation"));

        setTimeout(function () {
            dropdown.addClass(hideClass);
            sliding = false;
        }, 500);
    } else {
        dropdown.removeClass(hideClass);
        setTimeout(function () {
            dropdown.addClass(openClass);
            button.addClass(openClass);
            button.attr("aria-label", window.OverDrive.SiteCopy.get("ariaLabels.closeNavigation"));
        }, 1);
        setTimeout(function () {
            sliding = false;
        }, 500);
    }
}
